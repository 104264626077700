import React from 'react';
import { Helmet } from 'react-helmet';
import { createStyles, StyleRules, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import Layout from '../../layout/AdminLayout';
import CompanyCertificationView from '../../views/admin/CompanyCertificationView';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      width: 1128,
      margin: 'auto',
      height: theme.spacing(3),
    },
  });

const Companies = (): React.ReactElement => {
  return (
    <Layout>
      <Helmet title="企业认证"></Helmet>
      <CompanyCertificationView></CompanyCertificationView>
    </Layout>
  );
};

export interface Props extends WithStyles<typeof styles> {
  title?: string;
}

export default withStyles(styles)(Companies);
