import React, { ReactElement, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  createStyles,
  Dialog,
  DialogContent,
  Paper,
  StyleRules,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import DialogTitle from '../../components/DialogTitle';
import CompanyCertificationDetailsView from './CompanyCertificationDetailsView';
// import { AppState } from '../../reducers';

const styles = (): StyleRules =>
  createStyles({
    DataGridMargin: {
      minHeight: '80vh',
    },
    contentPadding: {
      padding: '10px 0',
    },
    childrenItem: {
      width: '100%',
      borderRadius: '3px',
      // minHeight: '80vh',
      paddingBottom: '48px',
    },
    buttonMargin: {
      margin: '0 0.25rem',
    },
    DialogWidth: {
      minWidth: '850px',
      border: '20px solid red',
    },
    searchInput: {
      margin: '20px',
    },
    backgroundColor: {
      backgroundColor: '#ffffff',
    },
    textTitle: {
      textAlign: 'center',
    },
    multiple: {
      margin: '1rem',
    },
  });

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35, status: '已认证' },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42, status: '未认证' },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45, status: '编辑中' },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16, status: '未认证' },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null, status: '未认证' },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150, status: '未认证' },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44, status: '未认证' },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36, status: '未认证' },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65, status: '未认证' },
];

const companyCertificationView = ({ classes }: Props): ReactElement => {
  //   const dispatch = useDispatch();
  //   const projectListReducer = useSelector((appState: AppState) => appState.projectListReducer);
  const [open, setOpen] = React.useState(false);
  const [projectRowData, setProjectRowData] = useState(rows);
  setProjectRowData;
  const handleClose = () => {
    setOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: '企业名称',
      flex: 1,
    },
    {
      field: 'status',
      headerName: '认证状态',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '操作',
      type: 'number',
      width: 300,
      sortable: false,
      renderCell: () => (
        <Button
          type="button"
          variant="contained"
          color="primary"
          className={classes.buttonMargin}
          onClick={() => {
            setOpen(true);
          }}
        >
          <VisibilityIcon /> 查看
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.contentPadding}>
      <Paper square className={classes.searchInput}>
        <DataGrid
          rows={projectRowData}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          className={classes.DataGridMargin}
        />
      </Paper>

      <Dialog onClose={handleClose} open={open} maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          查看企业认证
        </DialogTitle>
        <DialogContent dividers>
          <CompanyCertificationDetailsView></CompanyCertificationDetailsView>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(companyCertificationView);
